import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaClock, FaFacebook, FaLinkedinIn, FaInstagram, FaTiktok } from 'react-icons/fa';

const Contact = () => {
  return (
    <div name='contact' className='w-full lg:h-[85vh] h-[80vh] bg-[#49062b] flex justify-center items-center p-4 text-white'>
      <div className="p-6">
        <h2 className="text-3xl font-bold mb-8 mt-2">Get In Touch</h2>
        <div className="flex items-center mb-6 text-xl">
          <FaMapMarkerAlt className="mr-4" />
          <p className='text-lg'>35, Adeola Hopewell Street, Victoria Island, Lagos</p>
        </div>
        <div className="flex items-center mb-6 text-xl">
          <FaPhone className="mr-4" />
          <a href="tel:+2348091973714" className="text-white text-lg">
            +2348091973714
          </a>
        </div>
        <div className="flex items-center mb-16 text-xl">
          <FaEnvelope className="mr-4" />
          <a href="mailto:office@kuramo.org" className="text-white text-lg">
            office@kuramo.org
          </a>
        </div>
         <p className='text-2xl mb-4 font-bold'>Office Hours</p>
        <div className="flex items-center mb-6 text-lg">
          <FaClock className="mr-4" />
          <p>Monday to Friday - 9:00 am to 4:00 pm</p>
        </div>
        <div className="flex items-center  mt-20 gap-3">
            <p className='text-2xl mr-2'>Follow us on</p>
          <a href="https://www.facebook.com/profile.php?id=61556523255238&mibextid=LQQJ4d">
            <FaFacebook className="mr-3 text-2xl cursor-pointer" />
          </a>
          <a href="http://linkedin.com/in/kuramo-development-trust-kdt-9600b72a6">
            <FaLinkedinIn className="mr-3 text-2xl cursor-pointer" />
          </a>
          <a href="https://www.instagram.com/kuramo_d_trust?utm_source=qr">
            <FaInstagram className="mr-3 text-2xl cursor-pointer" />
          </a>
          <a href="https://twitter.com/Kuramo_D_Trust">
            <FontAwesomeIcon icon={faXTwitter}  className="mr-3 text-2xl cursor-pointer" />
          </a>
          <a href="https://www.tiktok.com/@kuramo_d_trust?_t=8kEsfN1JC45&_r=1">
            <FaTiktok className="text-2xl cursor-pointer" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
