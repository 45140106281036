import React, { useState, useRef, useEffect } from 'react';
import {
  FaBars,
  FaTimes,

} from 'react-icons/fa';
import Logo from '../assets/img/pic1.jpg';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const sidebarRef = useRef(null);

  const handleClick = () => setNav(!nav);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setNav(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='fixed w-full h-[90px] flex justify-between items-center px-4 bg-[white] text-[#49062b]'>
      <div className=' lg:pl-5 pl-2'>
        <img src={Logo} alt='Logo' className='lg:w-[270px] w-[200px] rounded-sm' />
      </div>
      
      {/* menu */}
      <ul className='hidden md:flex font-roboto font-bold gap-5'>
        <li>
          <Link
            to='home'
            smooth={true}
            duration={500}
            className=' hover:text-[#93616c] hover:text-lg transition duration-300'
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to='about'
            smooth={true}
            duration={500}
            className=' hover:text-[#93616c] hover:text-lg transition duration-300'
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to='focus'
            smooth={true}
            duration={500}
            className=' hover:text-[#93616c] hover:text-lg transition duration-300'
          >
            Focus
          </Link>
        </li>
        <li>
          <Link
            to='project'
            smooth={true}
            duration={500}
            className='hover:text-[#93616c] hover:text-lg transition duration-300'
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            to='contact'
            smooth={true}
            duration={500}
            className='hover:text-[#93616c] hover:text-lgtransition duration-300'
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul
        ref={sidebarRef}
        className={
          !nav
            ? 'hidden'
            : 'absolute bg-gray-200  top-[90px] right-[-3px] w-[50%] h-[450px] flex flex-col rounded-md'
        }
      >
        <li className='py-6 text-xl mt-10 text-center'>
          <Link
            onClick={handleClick}
            to='home'
            smooth={true}
            duration={500}
            className='hover:underline transition duration-300'
          >
            Home
          </Link>
        </li>
        <li className='py-6 text-xl text-center'>
          <Link
            onClick={handleClick}
            to='about'
            smooth={true}
            duration={500}
            className='hover:underline transition duration-300'
          >
            About
          </Link>
        </li>
        <li className='py-6 text-xl text-center'>
          <Link
            onClick={handleClick}
            to='focus'
            smooth={true}
            duration={500}
            className='hover:underline transition duration-300'
          >
            Focus
          </Link>
        </li>
        <li className='py-6 text-xl text-center'>
          <Link
            onClick={handleClick}
            to='project'
            smooth={true}
            duration={500}
            className='hover:underline transition duration-300'
          >
            Projects
          </Link>
        </li>
        <li className='py-6 text-xl text-center'>
          <Link
            onClick={handleClick}
            to='contact'
            smooth={true}
            duration={500}
            className='hover:underline transition duration-300'
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Social icons */}
      {/* <div className='hidden lg:flex fixed flex-col top-[33%] left-0'>
        <ul>
          <li className='w-[160px] h-[55px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='www.facebook.com'
            >
              Facebook <FaFacebook size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[55px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='http://linkedin.com/in/kuramo-development-trust-kdt-9600b72a6'
            >
              linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[55px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-pink-600'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='www.instagram.com'
            >
              Instagram <FaInstagram size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[55px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gray-100'>
            <a
              className='flex justify-between items-center w-full text-blue-400'
              href='https://twitter.com/Kuramo_D_Trust'
            >
              Twitter <FaTwitter size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[55px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://www.tiktok.com/@kuramo_d_trust?_t=8kEsfN1JC45&_r=1'
            >
              Tiktok <FaTiktok size={30} />
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default Navbar;
