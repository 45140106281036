import React from 'react';
import { FaCheck } from 'react-icons/fa';
import Image1 from '../assets/img/iwollo3.jpg';
import Image2 from '../assets/img/computer.jpg';

const About = () => {
  return (
    <div name='about' className='w-full min-h-screen bg-white text-[#49062b]'>
      <div className="container lg:mx-auto lg:py-10 py-3">
        <h1 className="text-4xl font-bold text-center mb-8 mt-10">About Us</h1>
        <div className='flex flex-wrap items-start justify-center lg:px-28 px-3'>
          <div className='w-full md:w-3/5 lg:w-1/2 p-4'>
            <div className='mb-8 lg:flex gap-5'>
              <img src={Image1} alt="" className="w-full lg:w-[260px] h-[170px] rounded-md  mb-4 lg:mt-2" />
              <div>
                <h2 className="text-2xl font-bold mb-2">What We Do</h2>
                <p className="text-lg text-gray-500"> 
                  KDT supports various projects across the country. Thousands of households benefit directly and indirectly from these projects and other direct social interventions of KDT.
                </p>
              </div>
            </div>
            <div className='lg:flex gap-5'>
              <img src={Image2} alt="" className="w-full lg:w-[260px] h-[150px] rounded-md mb-4 lg:mt-2" />
              <div>
                <h2 className="text-2xl font-bold mb-2 lg:mt-[-10px]">How We Work</h2>
                <p className="text-lg text-gray-500">
                Kuramo Development Trust (KDT) has been operating for over 25 years, providing valuable support and resources to various projects aimed at strengthening education, formation, and social welfare in Nigeria.
                </p>
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/5 lg:w-1/2 p-4'>
            {/* <h2 className="text-2xl font-bold mb-2">Years of Experience</h2> */}
            <p className="text-lg text-gray-500">
              With our extensive experience, we continue to make a positive impact in the lives of individuals and families across the country.
            </p>
            <div className="mt-8">
              <div className="flex items-center text-lg mb-2">
                <FaCheck className="text-[#49062b] mr-2 mt-[-55px] md:mt-[-80px] lg:mt-[-27px] text-lg font-bold" />
                <p className='text-gray-500'>We foster the development of upright, socially responsible individuals and healthy families.</p>
              </div>
              <div className="flex items-center text-lg mb-2">
                <FaCheck className="text-[#49062b] mr-2 mt-[-27px] md:mt-[-55px] lg:mt-0 text-sm font-bold" />
                <p className='text-gray-500'>We collaborate with other NGOs with similar aims and objectives.</p>
              </div>
              <div className="flex items-center text-lg mb-2">
                <FaCheck className="text-[#49062b] mr-2 mt-[-27px] md:mt-[-55px] lg:mt-0 text-sm font-bold" />
                <p className='text-gray-500'>We support students undertaking advanced ecclesiastical studies.</p>
              </div>
              <div className="flex items-center text-lg mb-2">
                <FaCheck className="text-[#49062b] mr-2 mt-[-55px] md:mt-[-25px] lg:mt-[-27px] text-md lg:text-md font-bold" />
                <p className='text-gray-500'>We support various educational, health and rural development projects across the country.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
