import React, { useState, useEffect } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import image1 from '../assets/img/bg-copy.jpg'; 
import image2 from '../assets/img/bg2.jpg';
import image3 from '../assets/img/bg3.jpg';

const Home = () => {
  const [contents, setContents] = useState([
    {
      backgroundImage: image1,
      text: 'Welcome to Kuramo Development Trust, where compassion meets action!',
      buttonText: 'Donate Now'
    },
    {
      backgroundImage: image2,
      text: 'Help us make a difference in the lives of people!',
      buttonText: 'Support Now'
    },
    {
      backgroundImage: image3,
      text: 'Partner with us to deliver impactful programs',
      buttonText: 'Get Involved'
    }
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % contents.length);
      setTextIndex(0); // Reset text index when changing content
      setShowButton(false); // Hide button when changing content
    }, 6000); // Change content every 6 seconds

    return () => clearInterval(timer);
  }, [contents.length]);

  useEffect(() => {
    const textLength = contents[currentIndex].text.length;
    const typingInterval = setInterval(() => {
      setTextIndex(prevIndex => (prevIndex + 1));
      if (textIndex === textLength) {
        clearInterval(typingInterval);
        setShowButton(true); // Show button when text typing completes
      }
    }, 40); // Typing speed

    return () => clearInterval(typingInterval);
  }, [currentIndex, contents, textIndex]);

  return (
    <div
      name= 'home'
      className='w-full lg:h-screen h-[80vh] bg-cover bg-top'
      style={{ backgroundImage: `url(${contents[currentIndex].backgroundImage})` }}
    >
      <div className='lg:max-w-[800px] mx-auto px-8 flex flex-col justify-center h-full'>
        <h1 className='text-4xl sm:text-6xl font-bold text-white font-montserrat'>
          {contents[currentIndex].text.substring(0, textIndex)}
        </h1>
        {showButton && (
          <a
            className='w-[180px]'
            href='https://kdttopinitiative.org/donate-now/'>
            <button className=' w-[180px] text-white border-2 px-6 py-3 my-5 flex items-center bg-pink-600 hover:bg-pink-700 hover:border-pink-700'>
                {contents[currentIndex].buttonText}
                <span className='ml-3'>
                <HiArrowNarrowRight />
                </span>
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default Home;


// import React, { useState, useEffect } from 'react';
// import { HiArrowNarrowRight } from 'react-icons/hi';
// import image1 from '../assets/five.jpg'; // Import your background images
// import image2 from '../assets/three.jpg';
// import image3 from '../assets/two.jpg';

// const Home = () => {
//   const [contents, setContents] = useState([
//     {
//       backgroundImage: image1,
//       text: 'Welcome to our orphanage donation site!',
//       buttonText: 'Donate Now'
//     },
//     {
//       backgroundImage: image2,
//       text: 'Help us make a difference in the lives of these children!',
//       buttonText: 'Support Now'
//     },
//     {
//       backgroundImage: image3,
//       text: 'Join us in providing hope and love to every child!',
//       buttonText: 'Get Involved'
//     }
//   ]);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [showContent, setShowContent] = useState(false);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentIndex(prevIndex => (prevIndex + 1) % contents.length);
//       setShowContent(false); // Reset content visibility when changing content
//       setTimeout(() => {
//         setShowContent(true); // Show content after a short delay
//       }, 1000); // Delay before showing content, adjust as needed
//     }, 5000); // Change content every 5 seconds

//     return () => clearInterval(timer);
//   }, [contents.length]);

//   return (
//     <div className='flex justify-center items-center w-full h-screen bg-cover' style={{ backgroundImage: `url(${contents[currentIndex].backgroundImage})` }}>
//       <div className='text-center px-[300px]'>
//         <h1 className={`text-4xl sm:text-7xl font-bold text-white transition-opacity duration-2000 ${showContent ? 'opacity-100' : 'opacity-0'}`}>
//           {contents[currentIndex].text}
//         </h1>
//         <button className={`mt-4 text-white font-bold underline border-2 px-6 py-3 my-2 flex items-center  bg-none border-none hover:bg-pink-700 hover:border-pink-700 transition-opacity duration-2000 ${showContent ? 'opacity-100' : 'opacity-0'}`}>
//           {contents[currentIndex].buttonText}
//           <span className='ml-3'>
//             <HiArrowNarrowRight />
//           </span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Home;

