import React, { useState, useEffect, useRef } from 'react';
import project1 from '../assets/img/img3.jpeg';
import project2 from '../assets/img/img4.jpeg';
import project3 from '../assets/img/img5.jpeg';
import project4 from '../assets/img/img6.jpeg';
import project5 from '../assets/img/img8.jpeg';
import project6 from '../assets/img/img9.jpeg';
import project7 from '../assets/img/img10.jpeg';
import project8 from '../assets/img/img11.jpeg';

const Project = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const projects = [
    { id: 1, 
      image: project1, 
      title: 'Ugwuoma Study Centre', 
      description: 'Foundational Education', 
      more: 'Unlike other projects which focus on teenagers and university students, Ugwuoma focuses on adults/professional men.Set up in 1977, Ugwuoma understands the integral role men play in the family, workplace and society and seeks to empower them to develop personally, professionally and spiritually, in order to positively shape our civilization. It does this through personalised courses, conferences and seminars. The Family Enrichment Programme, a United Nations (UN) recognised programme, designed to offer married couples courses that will help them educate their children and improve their personal, married and family lives, is one of its successful programmes. Over the years, thousands of people both in Enugu metropolis and surrounding states have come to benefit from its varied activities. KDT contributed with grants towards completing and equipping the Centre.' },
    { id: 2, image: project2, title: 'Lakeside Centre', description: 'Educational / Youth Development', more: 'A student development centre, focused on helping students and fresh graduates realize the value and ethics of hard work, professional competence and academic excellence. Lakeside’s vision is that of young men with excellent educational and intellectual preparation for life, armed with the tools to face life’s challenges head on. KDT’s intervention here takes the form of subventions to fund the various programs of the Centre, as well as donations towards capital needs.' },
    { id: 3, image: project3, title: 'Southcreek Centre', description: 'Educational / Youth Development', more: 'Southcreek Centre aims at complementing the academic, moral and character training, which the students receive at home and at school. The development of boys is supported through extra-curricular activities. These activities give students the practical, intellectual, and moral foundation to be men of character. KDT’s intervention takes the form of subventions to fund the various programs of the centre.' },
    { id: 4, image: project4, title: 'Lantana College of Hospitality', description: 'Vocational Training', more: 'Lantana College a Project of Women’s Board of ECS is a training centre for the National Skills Qualification(NSQ). This is a work-based qualification , through which learners acquire competence in knowledge, skills and understanding of Hospitality subject areas. The College is an approved Centre for National Technical Certificate Examinations (NTC) conducted by the National Business and Technical Examination Board (NABTEB). Lantana empowers young girls with the skills and expertise needed to be effective in the hospitality career. KDT provides grants which help to reduce the overall cost of the college.' },
    { id: 5, image: project5, title: 'Aguobu Iwollo Health Centre', description: 'Rural Healthcare', more: 'Aguobu-Iwollo is a small rural community of about a thousand inhabitants and it suffers from healthcare deficiencies that are common in similar rural communities nation-wide. The Health Centre has the overall goal of promoting the social, health and economic well-being of the local population, with particular attention to women and children. The services provided to Iwollo and its surrounding communities are free or heavily subsidised. KDT grants periodic reliefs to assist in keeping the Centre running.' },
    { id: 6, image: project6, title: 'Eleko Study Centre', description: 'Educational / Youth Development', more: 'A Centre with state of the art facilities for academic and extra-curricular activities for students of the Pan-Atlantic University. The activities that are being developed in the Study Centre contribute not only to the educational but also to the social and cultural life of the students. The learning opportunities offered in the Centre, based on the long-standing and extensive international experience of similar Centres abroad, have always attracted good students who want to get the best results during their studies and prepare themselves for future professional careers. KDT contributed with grants towards completing and equipping the Centre' },
    { id: 7, image: project7, title: 'Hillpoint Study Centre', description: 'Educational / Arts and Culture', more: 'Hillpoint Study Centre was started in 1991. It is located almost opposite the University of Nigeria, Enugu Campus. The Centre seeks to inculcate virtues in the young men who frequent the Centre. These virtues will help them to be gentlemen, who possess excellent work ethics, high moral values and a deep spiritual life. Hillpoint Centre serves to provide training in the academic, professional, spiritual and moral aspects of life.' },
    { id: 8, image: project8, title: 'Iroto Development Centre', description: 'Vocational Training And Healthcare', more: 'Iroto Rural Development Centre was opened in December 1985 to provide basic vocational training as well as education in agricultural practices and nutrition to women and girls in the area, at a time when there was proliferation of nutritional deficiencies in the local population. It had been realized that the women of the area, the majority of whom had had very little formal education, needed help. With this background and with the experience of decades of working with local communities, it was recently decided to expand the activities of IRDC by developing a School of Hospitality and Catering. The school began in 2005 and enrollment is growing as the scope of the studies of the school become better known. The School is being expanded to cater for more young women. KDT has intervened periodically, assisting with the needs of IRDC. The recent expansion received a generous grant from KDT.' },
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setModalOpen(false);
  };

  return (
    <div name='project' className='w-full min-h-screen bg-white py-12'>
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-center mt-5">Some Projects</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 lg:p-24 p-10 pt-16">
          {projects.map(project => (
            <div key={project.id} className="flex flex-col items-center justify-center bg-[#93616c] text-white p-4 rounded-lg ">
              <img src={project.image} alt={project.title} className="w-full h-64 object-cover mb-4 rounded-lg" />
              <h2 className="text-lg font-bold">{project.title}</h2>
              <p>{project.description}</p>
              <button onClick={() => handleProjectClick(project)} className="mt-4 text-white underline">Read More</button>
            </div>
          ))}
        </div>
        {modalOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center" onClick={closeModal}>
            <div ref={modalRef} className="bg-white p-4 rounded-md w-[70%]" onClick={(e) => e.stopPropagation()}>
              <h2 className="text-xl font-bold mb-2">{selectedProject.title}</h2>
              <p>{selectedProject.description}</p>
              <p>{selectedProject.more}</p>
              <button onClick={closeModal} className="mt-4 text-[#49062b] underline">Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Project;
