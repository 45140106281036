import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/NavBar";
import Project from "./components/Project";
import Focus from "./components/Focus";
import "./App.css";



function App() {

  return (
    <div className={`min-h-screen`}>
      <Navbar  />
      <Home  />
      <About  />
      <Focus  />
      <Project  />
      <Contact  />
    </div>
  );
}

export default App;
