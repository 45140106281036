import React from 'react';
import { FaHandsHelping, FaPaintBrush, FaHospitalAlt, FaGraduationCap, FaUsers, FaSeedling } from 'react-icons/fa';

const Focus = () => {
  return (
    <div name='focus' className='w-full min-h-screen bg-[#93616c] text-white py-4'>
      <div className="container mx-auto w-[50%] md:w-[60%] lg:w-[60%] ">
        <h1 className="text-4xl font-bold text-center mb-16 lg:mb-20 mt-12">Our Focus</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 text-[#49062b] lg:ml-[8%] ">
          <div className="flex flex-col items-center justify-center w-[200px] h-[200px] bg-purple-200 rounded-lg">
            <FaGraduationCap className="text-6xl mb-4" />
            <p className="text-lg">Education</p>
          </div>
          <div className="flex flex-col items-center justify-center w-[200px] h-[200px] bg-green-200 rounded-lg">
            <FaHandsHelping className="text-6xl mb-4" />
            <p className="text-lg">Community</p>
            <p className="text-lg">Development</p>
          </div>
          <div className="flex flex-col items-center justify-center w-[200px] h-[200px] bg-red-200 rounded-lg">
            <FaHospitalAlt className="text-6xl mb-4" />
            <p className="text-lg">Healthcare</p>
          </div>
          <div className="flex flex-col items-center justify-center w-[200px] h-[200px] bg-yellow-200 rounded-lg">
            <FaPaintBrush className="text-6xl mb-4" />
            <p className="text-lg">Art and Culture</p>
          </div>
          <div className="flex flex-col items-center justify-center w-[200px] h-[200px] bg-indigo-200 rounded-lg">
            <FaUsers className="text-6xl mb-4" />
            <p className="text-lg">Youth Development</p>
          </div>
          <div className="flex flex-col items-center justify-center w-[200px] h-[200px] bg-blue-200 rounded-lg">
            <FaSeedling className="text-6xl mb-4" />
            <p className="text-lg">Sustainability</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Focus;
